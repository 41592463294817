const sgirotProjectPrice = {
  $validator: (value) => {
    if (! value) {
      return true;
    }
    if (typeof value !== 'number' || value > 10000) {
      return false;
    }
    return true;
  },
  // $message: 'test cool'
};

export default sgirotProjectPrice;
